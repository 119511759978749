import { styles, lineBreak } from "./AllEpisodesSetup";

function Ep3() {
  return (
    <div style={styles.div}>
      <p>
        We explained the difference between the Jannat and samawat. Most likely,
        the Jannat occupy the 7th heaven (sama).
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>
        Al-Isra/al-Mi'raj: Conversing With the Prophets
      </b>
      {lineBreak()}
      <p>
        As we said, one of the biggest problems with al-Isra wal-Mi'raj is to
        piece together the chronological narrative of the story. We have
        narrations the Prophet PBUH very clearly spoke to the other prophets,
        but where did this happen? It could be in the heavens or in the Bayt
        al-Maqdis.
        {lineBreak()}
        Abdullah ibn Mas'ud reports, "The Prophet PBUH said, on the night he
        went to al-Isra wal-Mi'raj, he met Ibrahim, Musa, and Isa, and they
        began talking about the Day of Judgement. And Ibrahim was the first to
        be asked, 'Tell us about the Day of Judgement,' but he said, 'I have no
        knowledge of the Day.'" Then they asked Musa and same response. Then
        they asked Isa. He said, "I am informed one of the signs of it is I will
        be coming back (to earth) —and Allah SWT knows when that is— and Dajjal
        will come, and I will kill him. And the people will then go to their
        various lands, and Ya'juj and Ma'juj will come forth 'from every single
        valley/area' [21:96], and every water they pass by will be
        drunk/finished until I will make du'a to Allah that they be killed, so
        Allah will kill them, and the entire world will be stenched with their
        decomposed bodies, and I will then make another du'a to Allah to get rid
        of these bodies, so Allah will send rain from the sky and they will be
        washed away. When this happens, the Day of Judgement will be like a
        pregnant woman who is just about to give birth."
        {lineBreak()}
        And the Prophet PBUH said whatever Isa AS said is found in the Qur'an,
        "Until when [the dam of] Gog and Magog has been opened and they, from
        every elevation, descend" [21:96], etc.
        {lineBreak()}
        So this conversation has been preserved, but anything else they talked
        about, Allahu a'lam.
        {lineBreak()}
        Note also a lot of the hadith with the Prophet PBUH narrate about Ya'juj
        and Ma'juj, about Isa coming down, the Day of Judgement, etc., this
        incident shows one source is from Isa AS directly.
        {lineBreak()}
        Another conversation recorded is when the Prophet PBUH said, "I met
        Ibrahim AS on the night I went to al-Isra, and he told me, 'Ya Muhammad,
        give my salam to your ummah...'"—Ibrahim AS is telling his salam to us
        through his own son, the Prophet PBUH—"'...and inform them that Jannah,
        its soil is beautiful and lush, but it is barren (flat without any
        trees), and the seedlings we put into the soil will come from
        'subhan'Allah,' 'alhamduliLlah,' 'la ilaha illaLlah,' 'Allahu akbar.''"
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>
        Al-Isra/al-Mi'raj: Malik, the Gatekeeper of Jahannam
      </b>
      {lineBreak()}
      <p>
        This could have happened in the Bayt al-Maqdis or in the heavens (we
        don't know for sure): It's narrated the Prophet PBUH met Malik (مالك)
        the gatekeeper of Jahannam. His name is mentioned in the Qur'an: "And
        they will call, 'O Malik, let your Lord put an end to us!' He will say,
        'Indeed, you will remain'" [43:77].
        {lineBreak()}
        Jibril AS told the Prophet PBUH, "O Muhammad, this is Malik, the
        gatekeeper of Jahannam. Give him salam," so the Prophet PBUH said he
        turned around to greet him, but before he could say anything, Malik said
        salam first. The Prophet PBUH replied with salam as well. The Prophet
        PBUH further narrates Malik seemed very sad and never smiled and so the
        Prophet PBUH asked Jibril AS why this is. (Note: The fact that the
        Prophet PBUH asked this question shows the other angels clearly had a
        very positive/joyful/happy vibe coming from them — and this shows us
        angels were happy when they saw the Prophet PBUH.) So Jibril AS
        responded, "He has never smiled or laughed since he has been created.
        Were he to have smile for anybody, it would have been you"—his
        association of guarding Jahannam has made him so somber that he has
        never smiled or laughed since he has been created.
        {lineBreak()}
        Even though the Prophet PBUH is going to see Jannah and Jahannam, he
        didn't visit Malik while he was 'on-duty' at the gate of Hellfire.
        Rather, Malik was brought up to say salam to him. Why? One of the
        wisdoms we derive is: To emphasize that the Prophet PBUH is as far away
        from the Fire of Hell as the 7th heaven is from the bottom of the
        creation. (Even though Malik himself is a noble angel, but because the
        thing he is associated with is Jahannam.) And that's also why Malik was
        the one who said salam first — to show there is no relationship at all
        between the Prophet PBUH and the Hellfire; to clearly show the distance
        and superiority of the Prophet PBUH from ever coming close to anything
        to do with Jahannam.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>Al-Mi'raj: Sidrat al-Muntaha (سدرة المنتهى)</b>
      {lineBreak()}
      <p>
        The Prophet PBUH then proceeded onward above the 7th heaven after seeing
        the prophets. He PBUH said, "And then I saw in front of me the Sidrat
        al-Muntaha."
        {lineBreak()}
        What is Sidrat al-Muntaha?
        {lineBreak()}
        For the Arabs, what comes to mind when the word sidrah is mentioned is a
        type of wide tree that grows in the desert known for its large branches
        that cover a very wide and vast area, and it's known for its delicious
        fruit and sweet scent.
        {lineBreak()}
        And muntaha means "the very end."
        {lineBreak()}
        So Sidrat al-Muntaha (English: Lote Tree) means the "Tree at the Very
        End." And the Prophet PBUH said, "The fruits of this tree were as large
        as the water jars [of the people] of Hajar, and its leaves are like the
        ears of the elephants"—this is what we call eloquence; the Prophet PBUH
        explains something using metaphors that the people in his time can
        understand.
        {lineBreak()}
        After viewing it, Jibril AS told the Prophet PBUH, "This is Sidrat
        al-Muntaha."
        {lineBreak()}
        In a hadith in Bukhari, the Prophet PBUH said, "Then Jibril AS continued
        going up with me, until we got to the Sidrat al-Muntaha. And it was
        enveloped with colors I don't know." Allah SWT Himself said in the
        Qur'an, "When the Tree was covered up by what it was covered up with"
        [53:16]—Allah does not tell us specifically what the cover is. (And it's
        interesting to note there are colors beyond the spectrum that we know
        of. The Prophet PBUH is seeing colors that cannot be seen in this world.
        And this fits in very well with whatever we know of science — the
        spectrum of colors we see in this world is limited to whatever the
        source of our light generates. So the fact that the Prophet PBUH is
        seeing colors that can only be generated by a different type of light
        shows he is definitely in another zone/dimension during al-Mi'raj. The
        Prophet PBUH is saying something so simple for the casual observer, but
        it has a deep and profound scientific significance to it.)
        {lineBreak()}
        According to Bukhari, Sidrat al-Muntaha is the last thing the Prophet
        PBUH saw before he went above to meet Allah SWT.
        {lineBreak()}
        But in Sahih Muslim, Abdullah ibn Mas'ud said the Prophet PBUH said, "I
        stopped at the Sidrat al-Muntaha, it is in the 6th heaven. Everything
        that is raised up from the earth (e.g. du'a, prayer, good deeds, good
        words) stops at Sidrat al-Muntaha, and it absorbs them. And from it
        originates and descends down everything that is coming to this earth
        (e.g. Allah's rahma, rain). When the Tree was enveloped by what it was
        enveloped by, there were butterflies made out of gold." Allah SWT says
        in Surah al-Najm, "When there covered the Sidrat al-Muntaha that which
        covered [it], the sight [of the Prophet] did not swerve, nor did it
        transgress [its limit]. He certainly saw of the Greatest Signs of his
        Lord" [53:16-18]. So by viewing the Sidrat al-Muntaha, Allah is saying
        the Prophet PBUH has seen of His most magnificent creations. In one
        version in al-Tabari, the Prophet PBUH said, "When it was enveloped by
        what it was enveloped by, it kept on changing until nobody could have
        described it"—so the Tree is not a static tree. It's a dynamic, majestic
        Tree that's out of this world, with constantly changing colors, etc.,
        described as, "Ayat al-Kubra (Greatest Signs)," [53:18] in the Qur'an.
        {lineBreak()}
        Note in one hadith in Bukhari, the Prophet PBUH is recorded to have said
        he passed Ibrahim AS in the 7th heaven and then saw the Sidrat
        al-Muntaha, but in Muslim, he PBUH is recorded to have said he saw the
        Sidrat al-Muntaha in the 6th heaven, so there is clear contradiction.
        Imam al-Nawawi tries to explain this and it seems like a good
        explanation: he says the trunk of the Tree begins in the 6th heaven, but
        its branches finish at the end of the 7th heaven — because the Sidrat
        al-Muntaha is the end of the creation as we know it; nothing is beyond
        it except the Throne and the One who is above the Throne.
        {lineBreak()}
        In fact, later scholars have found one of the tabi'un Qatada, the
        student of the student of Ibn Abbas, also says this, that, "The Sidrah
        FINISHES in the 7th heaven"—which means it begins somewhere else.
        {lineBreak()}
        Thus from these we derive the Sidrah is a massive Tree.
        {lineBreak()}
        And note the motif/concept of a tree is very common throughout the
        Qur'an. It is the symbol of Iman, life, etc. It is in the fitrah of the
        children of Adam that when we see a tree, we are optimistic. To this day
        many NGOs use the symbol of a leaf/twig. Allah ingrained in our fitrah
        that it is a symbol of optimism, peace, and life.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>Al-Mi'raj: The Four Rivers</b>
      {lineBreak()}
      <p>
        Then the Prophet PBUH said, "At the base of the Sidrat al-Muntaha, there
        are four rivers coming down. 2 of these are hidden, and 2 are open. So I
        said to Jibril what are these rivers? Jibril AS said, 'As for the hidden
        ones, they are ones in Jannah (i.e. you are not going to see them in the
        dunya). As for the ones everybody can see, they are the Nile river and
        the Euphrates river.'" From this we learn the Nile and the Euphrates are
        blessed rivers. And subhan'Allah, the Prophet PBUH again just makes a
        passing statement that has a profound historical significance: Now we
        know the cradle of civilization has always been associated with these
        two rivers. The earliest civilization on earth was basically Mesopotamia
        (Ur, Babylon, etc.), which is associated with the Euphrates; and the
        second was on the Nile. These two rivers from the beginning of time have
        always been rivers of life and civilization. And Jibril AS said both
        rivers are from the blessings of Allah that everybody can see; they
        emanate from Up There but apparent for everybody [in the dunya] to see.
        As for the two rivers of Jannah [that are hidden], they are al-Kawthar
        [108:1] and Salsabil [76:18].
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>Al-Mi'raj: Al-Bayt al-Ma'mur</b>
      {lineBreak()}
      <p>
        In one version of the narration, the Prophet PBUH is recorded to have
        said, "THEN I saw al-Bayt al-Ma'mur." In another version, "I saw Ibrahim
        AS in the 7th heaven, sitting with his back on Bayt al-Ma'mur."
        {lineBreak()}
        Now was this one incident and somehow it got into these two narrations,
        or was it two separate times the Prophet PBUH saw Bayt al-Ma'mur? Allahu
        a'lam. We don't know exactly. This often happens in the narrations of
        al-Isra wal-Mi'raj (and seerah in general) as it's the sahaba narrating
        and there is a human element of documenting the ahadith that we need to
        be aware of — it's impossible to reconstruct A-Z. So we're not sure
        exactly what happened when.
        {lineBreak()}
        As we mentioned before, the Prophet PBUH said, "The Bayt al-Ma'mur is a
        House similar to the Ka'bah [on earth]..." (or in another hadith, "It is
        above the Ka'bah on this earth...") "...such that if it were to fall
        down, it will land on the Ka'bah on the earth." And this is when he
        said, everyday 70,000 angels enter it never to return; this has been
        happening since Allah created it until Allah knows when — and of course
        if you do the math, after a while your mind starts boggling away. How
        many angels are there? Allah says in the Qur'an only He knows how many
        soldiers (angels) there are [74:31].
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>Al-Mi'raj: Jibril AS in His Original Form</b>
      {lineBreak()}
      <p>
        After this the Prophet PBUH said he saw Jibril AS in his original form.
        And he PBUH described him in many ahadith. One of them, in Sahih
        Bukhari, "Jibril AS had 600 wings. He blocked the entire horizons." In a
        hadith in al-Tabari, "From the feathers of Jibril's wings, pearls and
        corals were dripping." So just like Sidrat al-Muntaha, Jibril AS is not
        a static creature. Note 600 is the maximum number of wings any angel
        has, as Allah says in the Qur'an, most of the angels have "2, 3, or 4
        wings" [35:1]. But Jibril AS has 600 wings because he is the best of the
        angels.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>Al-Mi'raj: The Major Signs of Allah</b>
      {lineBreak()}
      <p>
        Ibn Mas'ud said when the Prophet PBUH saw Jibril, this is the reference
        in the Qur'an: لَقَدْ رَأَىٰ مِنْ آيَاتِ رَبِّهِ الْكُبْرَىٰ "He saw of
        the Major/Greatest Signs of his Lord." [53:18]
        {lineBreak()}
        What does it mean the Prophet PBUH saw the Major Sings? 3 things:
        {lineBreak()}
        1. Sidrat al-Muntaha
        {lineBreak()}
        2. Bayt al-Ma'mur
        {lineBreak()}
        3. Jibril's original form
        {lineBreak()}
        Every one of them is of Allah's Major Signs. The Sidrat al-Muntaha
        represents the highest portion of this universe, the Bayt al-Ma'mur
        represents the spiritual Ka'bah of the heavens & the worship of Allah by
        the angels, and Jibril represents the most chosen of Allah's servants
        amongst the angels (so the most chosen amongst the children of Adam is
        seeing the most chosen amongst the angels in his original form. And it's
        said the Prophet PBUH only saw Jibril AS in his original form twice —
        this is one of those two occasions.)
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>
        Al-Mi'raj: 3 Things That Were Given During al-Mi'raj
      </b>
      {lineBreak()}
      <p>
        Somewhere in this period, the Prophet PBUH was given the last verses of
        Surat al-Baqarah. Narrated in Sahih Muslim, Ibn Mas'ud said the Prophet
        PBUH stopped at the Sidrat al-Muntaha in the 6th heaven [...] then he
        was given three things:
        {lineBreak()}
        1. The five salah (will be discussed later)
        {lineBreak()}
        2. The ending of Surat al-Baqarah
        {lineBreak()}
        3. Promise from Allah (gift from Allah) that whoever worship Allah from
        his ummah without doing shirk will be forgiven and caused to enter
        Jannah
        {lineBreak()}
        In regards to Baqarah, scholars say (opinion) the last two verses of
        Surat al-Baqarah occupy a special status in the Quran. As for the rest
        of the Quran, Jibril came down with it and recited it to the Prophet
        PBUH, but there is only one passage in which Allah directly recited it
        to the Prophet PBUH in His presence, and that is the last two verses of
        Surat al-Baqarah. The Messenger was brought up to receive the revelation
        rather than the revelation being sent down. Again, this is just an
        opinion — not something we can say for sure.
        {lineBreak()}
        In a hadith, the Prophet PBUH said, "I have been given these two verses
        from underneath the treasures of the Throne of Allah"—so this adds to
        what Ibn Mas'ud said.
        {lineBreak()}
        We know the virtues of these verses: (1) The Prophet PBUH said whoever
        recites these two verses, Allah will protect him. (2) And in another
        hadith, whoever recites these verses on a nightly basis, that will be
        enough for him. And there are various interpretations as to what
        "enough" here means: (i) One scholar says it will protect you from
        shaytan; (ii) others say even if you don't pray tahajjud, Allah will
        write you amongst those who are not ghafil (غافل - heedless), etc.
        Various interpretations.
        {lineBreak()}
        So we should now memorize it and make it a habit to recite it every
        night.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>
        Al-Mi'raj: The Divine Appointment: Private Audience With Allah SWT
      </b>
      {lineBreak()}
      <p>
        It was then the Prophet PBUH went to the gist of al-Isra wal-Mi'raj, and
        that is the Divine Appointment with Allah SWT Himself.
        {lineBreak()}
        But going through the classical books, narrations, and dissertations,
        sadly, we cannot find anything authentic about the *details* of the
        meeting. There are many legends and fabrications that are not true; of
        them is that the tashahhud is a summary of this conversation — but this
        is fabricated.
        {lineBreak()}
        Did the Prophet PBUH go beyond the Sidrat al-Muntaha alone or with
        Jibril AS? There is nothing except an indirect reference in Sahih
        Bukhari: The Prophet PBUH said, "Then I was caused to ascend forth"—so
        'I' is used in the singular, no mention of Jibril, whereas before this
        it was always "Jibril continued going up with me." Allah knows best but
        we can assume from this the Prophet PBUH rose beyond the Sidrat
        al-Muntaha alone.
        {lineBreak()}
        Then he said, "I rose to a level where I could hear the Pen writing."
        Now what is the Pen? And who is the writer? Well, we know —as the
        Prophet PBUH said— the first thing Allah created was the Pen, and then
        He told the Pen, "Write." The Pen asked, "What shall I write?" He said,
        "Write everything that will happen until the Day of Judgement." So the
        Pen is a creation of Allah that is writing alone; there is no scribe
        doing the writing.
        {lineBreak()}
        This was an elite audience the likes of which never occurred. But we
        don't have any details about what was said except for the 50 salah (see
        next subtopic). Even though we can assume other things were also said,
        but the only thing that has been reported to us is that of 50 salawat.
        And in this perhaps there is a wisdom, that what happened is something
        private — Allah gifted the Prophet PBUH with something so precious,
        noble, and blessed that he doesn't need/want to tell us. Only Allah and
        the Prophet PBUH know the full conversation.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>
        Al-Mi'raj: The Divine Appointment: The Farida of 5 Salawat
      </b>
      {lineBreak()}
      <p>
        The only thing we know is Allah told him to pray 50 salah (plural
        salawat) every day. And the Prophet PBUH goes back down through the
        heavens and meets Musa AS who tells him to go back up to ask for less.
        Now before we get to that, the Prophet PBUH must have passed by Ibrahim
        AS on the 7th heaven, but Ibrahim AS didn't say anything. Why? Three
        reasons:
        {lineBreak()}
        1. Ibrahim AS is at a higher level and it's not in his nature to try to
        second-guess or question the commandment of Allah. Whereas Musa AS is
        not at that level — he wants to try to negotiate.
        {lineBreak()}
        2. Ibrahim AS has no experience with large ummahs — whereas Musa AS is
        the most experienced up until this time. We are still in the Makkan
        phase so at this point the Prophet PBUH is only active for 11, 12 years
        whereas Musa has at least 80-90 years of experience with the Bani
        Israel.
        {lineBreak()}
        3. Musa knew something was going to happen in this meeting. How does he
        know? He's been there done that at a smaller scale, as of course Musa is
        the only prophet whom Allah AWJ spoke to directly on earth. Our Prophet
        PBUH was granted a divine audience in the presence of the King of kings.
        So just as Musa was given the Ten Commandments, he knew the Prophet PBUH
        would be given something.
        {lineBreak()}
        So Musa asks him, "What did your Lord tell you for your ummah?" The
        Prophet PBUH said, "My Lord told me I should tell my ummah to pray 50
        times a day." Here Musa says, "Go back to your Lord and tell Him to
        lower this because I have experience with the Bani Israel, and your
        ummah will not be able to do this." [The following is a detail that many
        people don't know, but it's in Musnad Imam Ahmad] The Prophet PBUH then
        looked at Jibril AS wanting to get his opinion. And Jibril nodded to
        him. This shows us despite Musa being who he is, the Prophet PBUH was
        not content until he got a second opinion. So backed with the opinion of
        the best angel and a noble prophet, the Prophet PBUH goes back up.
        {lineBreak()}
        Here is where the narrations differ. Some say it went down by 5, some
        say by 10 (both authentic, there is no way to resolve), but the point is
        the Prophet PBUH went back and forth, up and down for at least five
        times, and every time, Musa AS is telling him the same thing, "Go back
        to your Lord and ask Him to lower it, because I have tested the Bani
        Israel and they were not be able to do this. Your ummah won't be able to
        do this," until finally when the Prophet PBUH came back down with only 5
        and Musa AS told him to go back one more time, the Prophet PBUH said, "I
        have gone back and forth until I am embarrassed now. But I am content
        and happy." And when he said this, a Voice called out (of Allah): "My
        farida has been established and I have made things easy for My servants.
        It is five, but it shall be rewarded with fifty." What this means is
        Allah SWT had already decreed it would be five; Allah already knew the
        Prophet PBUH would come back to Him; so the Prophet PBUH was given the
        ilham to know when to stop. And this is why when it was reduced to five,
        the Prophet PBUH said what he said. And then the Voice came out, and the
        two people hearing it are the two people who have already had an
        audience with Allah — Musa AS and the Prophet PBUH.
        {lineBreak()}
        From this incident so much can be derived:
        {lineBreak()}
        1. First and foremost, the status of the salah. Indeed, the purpose of
        the divine audience was to establish the salah. Every other commandment,
        Allah sent down through Jibril AS; but this one commandment was so
        important the Messenger was called up to Allah to receive the message
        directly from the Divine. And if this is the only blessing we have of
        salah, it is enough of a blessing; but added to this, so many verses of
        the Quran ([2:3] [20:14] [11:114] [19:31] etc.) and so many ahadith talk
        about its blessing. Salah is standard among every single prophet. All of
        these show the importance of salah.
        {lineBreak()}
        2. Another benefit is when the Prophet PBUH was told he needed to pray
        50x a day, even though it was lowered to 5, he made it compulsory upon
        himself to pray 50 rak'at a day. We see how:
        {lineBreak()}
        17 fard rak'at<br></br>
        12 rak'at for sunan al-ratibah<br></br>2 rak'at before tahajjud<br></br>
        8 rak'at for tahajjud<br></br>3 rak'at for witr<br></br>8 rak'at for
        salat al-duha<br></br>
        ————————————<br></br>
        50 rak'at on the dot<br></br>
        {lineBreak()}
        This was the regular routine of the Prophet PBUH, even though this is
        not wajib for the ummah.
        {lineBreak()}
        And this is just the regular, not including tahiyyat al-masjid,
        istikharah, etc.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>
        Al-Mi'raj: The Wisdoms of Bringing Down the Number Gradually From 50 to
        5
      </b>
      {lineBreak()}
      <p>
        What is the wisdom of bringing down a number when Allah knows it will be
        brought down? Why go through this whole routine from 50 to 45...to 10 to
        5? Many reasons:
        {lineBreak()}
        1. To show the mercy and majesty of Allah SWT. He doesn't want to make
        life difficult for us — He wants to make things easy for us as it's
        mentioned so many times in the Qur'an (e.g. [4:28] [2:185] [22:78]).
        There is a wisdom to *demonstrate* that when the Prophet PBUH asks Allah
        to reduce it, He listens.
        {lineBreak()}
        2. Another wisdom is there is a clear indication given that, "O son of
        Adam, you are only created for the worship of Allah." That is, if it was
        50x a day we would literally be praying all day non-stop. So there's a
        clear indication that we have only been created for the worship of
        Allah. The asl (general rule) is we should pray like the angels. So
        Allah is indicating, "You should be praying 50x a day, but I know you
        can't do it, so do as you please, but pray this 5 salawat." And
        subhan'Allah, isn't it so sad then that after all of this, the bulk of
        the ummah cannot even spend ~20 minutes a day for salah?
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>After the Meeting With Allah SWT</b>
      {lineBreak()}
      <p>
        So the meeting with Allah SWT is the main incident, but it's not the
        grand finale, there's more to follow.
        {lineBreak()}
        After this, the Prophet PBUH described many other things he saw on the
        way down, including Jannah and Jahannam (will be discussed in the next
        episode).
        {lineBreak()}
        Question: Why does he see all these things *after* the meeting with
        Allah SWT? Why not before? The wisdom here is obvious. That is, it's not
        befitting that the meeting with the King of kings be delayed. So he is
        going straight up, and once he meets and speaks with Allah, then he may
        go and do other things.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>
        Al-Mi'raj: Did the Prophet PBUH See Allah SWT?
      </b>
      {lineBreak()}
      <p>
        We used the word "meeting" Allah SWT and that is an appropriate word,
        however, scholars differ as to whether the Prophet PBUH literally saw
        Allah. The majority and correct view is he didn't. He only saw the Veil
        of Allah (will be discussed in the next episode).
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>
        Understanding Surat al-Najm in Light of al-Mi'raj
      </b>
      {lineBreak()}
      <p>
        The beginning portions of Surat al-Najm describes certain of these
        incidents in indirect language:
        {lineBreak()}
        "By the star when it descends,<br></br>
        Your companion [Muhammad] has not strayed, nor has he erred,<br></br>
        Nor does he speak from [his own] inclination/imagination [i.e. what he
        saw is not just a dream].<br></br>
        It is not but a revelation revealed [from Allah],<br></br>
        Taught to him by one intense in strength [Jibril] -<br></br>
        One of soundness [perfect, free of any defect]. And he rose to [his]
        true form<br></br>
        While he was in the higher [part of the] horizon [i.e. Sidrat
        al-Muntaha].<br></br>
        Then he [Jibril] approached [Muhammad] and descended<br></br>
        And was at a distance of two bow lengths or nearer.<br></br>
        And He [Allah] revealed to His Servant what He revealed [i.e. there's
        secrecy here that we don't need to know — all we need to know is the 5
        salawat].<br></br>
        The heart did not lie [about] what it saw [note: Allah ascribed the
        vision to the chest because the Prophet's PBUH eyesight absorbed it in,
        and when he's speaking, he's speaking the truth].<br></br>
        So will you dispute with him over what he saw?<br></br>
        And he certainly saw him in another descent<br></br>
        At the Sidrat al-Muntaha of the Utmost Boundary -<br></br>
        Near it is the Garden of Refuge -<br></br>
        When there covered the Sidrat al-Muntaha that which covered [it].
        <br></br>
        The sight [of the Prophet] did not swerve, nor did it transgress [its
        limit].<br></br>
        He certainly saw of the Greatest Signs of his Lord."<br></br>
        [53:1-18]
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>Q&A: The 5 Levels of Qadr</b>
      {lineBreak()}
      <p>
        Q: If the Pen is still writing, is qadr still being written as we speak?
        Can it be changed?
        {lineBreak()}
        A: There are 5 levels/types of qadr:
        {lineBreak()}
        Al-Qadr al-Azali — What Allah wrote before He created the heavens and
        earth by 50,000 years. (This never changes)
        {lineBreak()}
        Allah made a divine decree after the creation of Adam. He took out all
        of the progeny of Adam (i.e. all of the humans from the Beginning of
        Time until the End of Time), and asked them, "Am I not your Lord?" and
        all of the humans responded, "We testify that You are our Lord!" Then
        Allah decreed to them who shall go to Jannah and who shall go to
        Jahannam.
        {lineBreak()}
        Lifetime qadr — When a child is in the womb of the mother, Allah SWT
        orders an angel to write down his deeds, his livelihood, his (time of)
        death, and whether he will be blessed or wretched (in religion) [Sahih
        Bukhari].
        {lineBreak()}
        Yearly qadr — Laylat al-Qadr (can change — Allah has already decreed in
        al-Qadr al-Azali that they're going to change; but nobody, not even the
        angels, knows this except Allah).
        {lineBreak()}
        Daily qadr — [Quran, 55:29]. (can change — same as above, but happens on
        a daily basis).
        {lineBreak()}
        Side note: Angel of Death — Every one of us has a Malak al-Mawt (ملك
        الموت), an angel whose sole job is to take our soul when our time is
        done [Quran, 32:11], but it is possible that if you are good to your
        relatives for example, your lifespan will be increased [based on a
        hadith]. يَمْحُو اللَّهُ مَا يَشَاءُ وَيُثْبِتُ ۖ وَعِندَهُ أُمُّ
        الْكِتَابِ "Allah blots out (erases) what He wills and confirms (what He
        wills). And with Him is the Mother of the Book (al-Lawh al-Mahfuz)"
        [Quran, 13:39].
      </p>
    </div>
  );
}

export default Ep3;
