import "./Episode.css";
import videoIcon from "./images/video-icon.png";
import { useNavigate } from "react-router-dom";

function Episode({ Episode, Title, Video, setEpisodeTitleNested, setEpisodeNumNested }) {
  let navigate = useNavigate();
  let routePath = `/content/episode${Episode}`;

  const handleClick = () => {
    navigate(routePath);
    setEpisodeTitleNested(Title);
    setEpisodeNumNested(Episode)
  };

  return (
    <div className="episodeContainer">
      <div className="episodeMain">
        <a
          href={Video}
          target="_blank"
          className="youtubeVideo"
          rel="noreferrer"
        >
          <img src={videoIcon} alt="video" className="videoIcon"></img>
        </a>
        <button
          type="button"
          onClick={() => {
            handleClick();
          }}
          className="episodeBtn"
        >
          <span>EPISODE {Episode}</span>
          <p>{Title}</p>
        </button>
      </div>
    </div>
  );
}

export default Episode;
