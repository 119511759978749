import { styles, lineBreak } from "./AllEpisodesSetup";

function Ep1() {
  return (
    <div style={styles.div}>
      <p>
        Insha'Allah (إن شاء الله - God willing), before we embark on the journey
        of seerah, we will study the Prophet's PBUH (ﷺ - May Allah Sends
        Blessings and Peace Upon Him/Peace Be Upon Him) shama'il (شمائل -
        characteristics) to build up even greater eagerness and motivation to
        learn about the life and times, the lessons and morals, the incidents,
        that occurred in the life of our Prophet Muhammad PBUH.
        {lineBreak()}
        Where do we begin when it comes to describing the one whom Allah (الله)
        has chosen above the entire creation. How can we do justice to him when
        Allah AWJ (Azza wa Jal/عز وجل - Mighty and the Majestic) Himself says,
        "We have raised up your remembrance and mention" [see Quran, 94:4]. And
        indeed, Ibn Abbas (ابن عباس) and other scholars of the sahaba (صحابة -
        Companions of the Prophet) said, "Allah has raised up his remembrance
        such that whenever Allah is mentioned, the Prophet PBUH is mentioned
        right after." Allah SWT (Subhanahu wa Ta'ala/سبحانه وتعالى - Glorified
        and Exalted be He) Himself called our Prophet PBUH a "Rahma li al-Alamin
        (رحمة للعالمين - Mercy to the World)" — He is the embodiment of rahma
        (رحمة - mercy); he is the channel of Allah's mercy. Indeed the Prophet
        PBUH is mercy, his sending is mercy, his message is mercy, his teachings
        are mercy, and believing and acting upon what he has come with is a
        mercy. He is everything associated with mercy. So how then can we begin
        to do justice to the Prophet PBUH when Allah has praised him so highly.
        {lineBreak()}
        However, even if we cannot mention all of his blessings and
        characteristics, let us at least mention some.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>The Names of Prophet Muhammad PBUH</b>
      {lineBreak()}
      <p>
        We begin by mentioning some of the names that Allah AWJ gave our Prophet
        Muhammad PBUH. He had many names. Many of his names were given by later
        people (i.e. the sahaba, tabi'un, and the early scholars), and one of
        the famous scholars of the seerah has derived over 250 names for the
        Prophet PBUH; but we will only mention some of the names that Allah has
        given him, for those are the primary names that have the deepest
        meaning.
        {lineBreak()}
        From Jubayr ibn Mut'im (جبير بن مطعم), in Sahih Muslim, he said the
        Prophet PBUH said: "I have a number of names. I am Muhammad, and I am
        Ahmad, and I am al-Mahi —the one whom Allah erases kufr through—, and I
        am al-Hashir —people will be resurrected after me—, and I am al-Aqib
        —the one who has no prophet after—."
        {lineBreak()}
        And in another narration: "And I am Nabi al-Rahma, and Nabi al-Tawba,
        and al-Muqaffa, and Nabi al-Malahim."
      </p>
      {lineBreak()}
      We begin by mentioning some of the names that Allah AWJ gave our Prophet
      Muhammad PBUH. He had many names. Many of his names were given by later
      people (i.e. the sahaba, tabi'un, and the early scholars), and one of the
      famous scholars of the seerah has derived over 250 names for the Prophet
      PBUH; but we will only mention some of the names that Allah has given him,
      for those are the primary names that have the deepest meaning.
      {lineBreak()}
      From Jubayr ibn Mut'im (جبير بن مطعم), in Sahih Muslim, he said the
      Prophet PBUH said: "I have a number of names. I am Muhammad, and I am
      Ahmad, and I am al-Mahi —the one whom Allah erases kufr through—, and I am
      al-Hashir —people will be resurrected after me—, and I am al-Aqib —the one
      who has no prophet after—."
      {lineBreak()}
      And in another narration: "And I am Nabi al-Rahma, and Nabi al-Tawba, and
      al-Muqaffa, and Nabi al-Malahim."
      {lineBreak()}
      The two nouns that the Quran (قرآن) explicitly mentions are Muhammad
      (محمد) and Ahmad (أحمد).
      {lineBreak()}
      The name Muhammad is mentioned 4 times in the Quran (see [3:144], [33:40],
      [47:2], [48:29]), and the name Ahmad is mentioned 1 time from the tongue
      of Isa (عيسى - Jesus) AS (Alayhi al-Salam/عليه السلام - Peace Be Upon Him)
      in the Quran, "A messenger after me whose name will be Ahmad" [Quran
      61:6]. Both names come from the root حمد (h-m-d); and "hamd" means "to
      praise," but not any type of praise, it means to praise not in return for
      some favor given to you; a praise that is given simply because of the
      inherent characteristics in the one that you are praising; that is, he
      deserves to be praised for who he is. An object of perfection. He is
      worthy of being praised regardless of what he has done or has not done.
      And both of the Prophet's PBUH names —Muhammad and Ahmad— come from
      "hamd." Why? Because Allah has praised him, the angles have praised him,
      all the prophets have praised him, and every single one of mankind praises
      him directly or indirectly. As for praising him directly, these are the
      Muslims — we praise him directly; and as for praising him indirectly,
      these are the non-Muslims — even those who reject the Prophet PBUH by the
      tongue, they must praise his character and qualities. So he is praised in
      the heavens and the earth, in the previous umam (أمم - nations) and the
      present umam, in this dunya (دنيا - world) and the akhira (آخرة -
      Hereafter). This is the ultimate praise. There is no human before, now, or
      after, who is praised more than the Prophet PBUH.
      {lineBreak()}
      In a long sahih (صحيح - authentic) hadith (حديث - Prophetic tradition), it
      is narrated that on the Day of Judgement, everyone will know that Islam
      (إسلام) is the Truth (including the nonbelievers, even though of course it
      is too late to believe), so the people will all go to Adam (آدم - Adam) AS
      and say to him, "O Adam, you are our father, and Allah created you with
      His Hands, and Allah blew His ruh into you! Do you not see the situation
      your children are in?" They will ask him to go to Allah and beg Him to
      start the Reckoning and ask for forgiveness, but he will say, "I committed
      a sin I should not have done, and I am worried about myself"—and so he
      will say, "Nafsi (نفسي - myself), nafsi. Go to another person. Go to Nuh
      (نوح - Noah)." Humanity at large will then go to Nuh AS and make the same
      request, but he will also make an excuse, "I made a mistake which I should
      not have done. Allah told me not to ask anybody to be saved, but I asked
      Him to save my son and I disobeyed Him." So Nuh AS is also worried about
      himself and will say, "Nafsi, nafsi." Then they will go to Ibrahim
      (إبراهيم - Abraham) AS, and he will also make an excuse, "I made 3 lies"
      (even though technically they were not lies). Then they will go to Musa
      (موسى - Moses) AS who will also make an excuse, "I killed someone in
      anger" (even though it was an accident). Then they will go to Isa (عيسى -
      Jesus) AS who will also say he is not worthy. And so they will go to the
      Prophet Muhammad PBUH and beg him to be an intercessor and representative
      to go in front of Allah for all of humanity. To do what? To begin the
      Reckoning. Just to begin it. Because the Day of Judgement is so hard they
      will say, "Whatever comes let it come! Let us just move on to whatever it
      is! The tension is too much!" So they will beg the Prophet PBUH, and he
      will say, "Ana laha (أنا لها), ana laha — this is my job, this is my job."
      And because of this, all of humanity will praise him. And he will be given
      the Praiseworthy Station which in Arabic is called al-Maqam al-Mahmud
      (المقام المحمود). And because it is the Praiseworthy Station, who better
      then it be given to than the one who is Muhammad and Ahmad.
      {lineBreak()}
      Both Muhammad and Ahmad mean "the one who is ultimately praised," but what
      is the difference? Muhammad means he is being given continuous praise,
      time after time, praise after praise — from the beginning of time, up
      until our time, until the Day of Judgement, on the Day of Judgement, and
      after the Day of Judgement. So Muhammad is for the quantity of the praise.
      As for Ahmad, it means he is being given the best type of praise. So Ahmad
      is for the quality of the praise. And both Muhammad and Ahmad are combined
      in our Prophet PBUH who will be given the Praiseworthy Station.
      {lineBreak()}
      Why is it that Prophet Musa AS predicted our Rasul (رسول - Messenger) with
      the name of "Muhammad," and Prophet Isa AS predicted our Rasul with the
      name of "Ahmad"? The famous scholar Ibn al-Qayyim (ابن القيم) said the
      wisdom behind this is that the largest ummah (أمة - nation) of true
      believers after our ummah is that of the Bani Israel (بني إسرائيل -
      Children of Israel i.e. the Jews); therefore they were told the name that
      is fitting with them — because they were large in quantity. As for Prophet
      Isa AS, his followers were few, but they were very pious and great in
      quality — they were tortured and persecuted; the pagan Romans killed them,
      combed their skin with combs of iron, etc., but they persevered and they
      were truly devoted; so for them, the name Ahmad was mentioned.
      {lineBreak()}
      As for his third name, the Prophet PBUH explained it himself: Al-Mahi
      (الماحي) means the one whom Allah wipe out kufr through. "Through me,
      Allah SWT will wipe out kufr." Indeed the Arabian Peninsula was immersed
      in kufr, but in his own lifetime the entire peninsula then converted to
      Islam. And within 20-30 years, major bastions of kufr were converted to
      Islam and remained in Islam ever since; within 60-70 years, Islam reached
      to the borders of China and Andalus where it remains to this day. Al-Mahi
      — the one who erases and effaces kufr.
      {lineBreak()}
      Al-Hashir (الحاشر) — the mankind will be resurrected at the feet of the
      Prophet PBUH (i.e. right after him). What does this mean? One
      interpretation is that the coming of the Prophet PBUH is the first sign of
      the DoJ (Day of Judgement), and therefore the Prophet PBUH is al-Hashir.
      Another interpretation is that the Prophet PBUH will be the first person
      to be resurrected on the DoJ and then everyone else thereafter. Indeed the
      prophets will be resurrected first and foremost, then the salihun (صالحون
      - righteous) and the shuhada (شهداء - martyrs), and then the rest of the
      ummah. So al-Hashir is the one that will signal the Hashar (Day of
      Judgement).
      {lineBreak()}
      Al-Aqib (العاقب) — the successor; the one who cuts off; the one who is at
      the end. And by this it means he is the last prophet.
      {lineBreak()}
      Nabi al-Rahma (نبي الرحمة) — the prophet of rahma (mercy).
      {lineBreak()}
      Nabi al-Tawba (نبي التوبة) — the prophet of tawba (repentance). Meaning by
      believing in him and following his teachings, people can be forgiven.
      {lineBreak()}
      Al-Muqaffa (المقفى) — the one who comes at the end and makes the message
      of the previous prophets complete; or the one who makes the message of the
      previous prophets not needed anymore because he comes with the complete
      message.
      {lineBreak()}
      Nabi al-Malahim (نبي الملاحم) — the prophet that will signal lots of
      trials. And indeed the biggest trials the world will ever see (the Dajjal
      [دجال], the three major earthquakes, etc.) will occur in this ummah.
      {lineBreak()}
      <b style={styles.subtitle}>The Specialties of Prophet Muhammad PBUH</b>
      {lineBreak()}
      <p>
        As for the Prophet's PBUH khasa'is (خصائص - specialties) [something that
        only he was given and no other human being was given], some scholars
        have listed up to 50 unique and specific characteristics/blessings. Some
        of these include:
        {lineBreak()}
        1. (Obviously) He is the final prophet of Allah. No prophet has been the
        final; and Allah chose him to be the final. The Quran says: مَّا كَانَ
        مُحَمَّدٌ أَبَا أَحَدٍ مِّن رِّجَالِكُمْ وَلَٰكِن رَّسُولَ اللَّهِ
        وَخَاتَمَ النَّبِيِّينَ "Muhammad is not the father of any of your men,
        but (he is) the Messenger of Allah, and the seal of the prophets."
        [33:40]
        {lineBreak()}
        2. The prophethood of the Prophet PBUH had been decreed by Allah even
        before Adam AS existed; even before the ruh (روح - soul) was blown into
        Adam.
        {lineBreak()}
        One sahabi asked the Prophet PBUH, "When were you decreed to be a
        prophet, ya Rasulullah (يا رسول الله - O Messenger of Allah)?" The
        Prophet PBUH said, "When Adam was between the tin (طين - mud) and the
        ruh." In other words, when the ruh and tin of Adam were still not yet
        combined — were still two separate entities. Before the ruh was blown
        in, Allah had already decreed.
        {lineBreak()}
        3. The only prophet to have been sent for all of humanity — in fact the
        only prophet to have been sent even to the jinn. (Note: Somebody can say
        Adam and Nuh were sent for all of humanity. But Adam and Nuh were both
        exceptions that simply happened by a coincidence of early history; in
        that, Adam was the first human and naturally the only human beings in
        his time was his descendants. The same goes for Nuh: there was only one
        city, nation, group, community of people in the whole world in his time,
        so it was just a coincidence. It is not as if Adam & Nuh were intended
        to be sent for all of humanity.) The Prophet PBUH was sent to all of
        humanity; and even to the jinn. (Insha'Allah in the course of our
        lectures we will mention the incident of the Night of the Jinn — the
        night in which the jinns came to Makkah [مكة - Mecca] to learn about
        Islam.)
        {lineBreak()}
        4. The Prophet PBUH said, "Allah has helped me with ru'b (رعب - a type
        of fear) — Allah will inflict into my enemies fear even one month
        journey away before I reach them." This is unique to the Prophet PBUH
        only. When he went into battle, people became terrified of him even
        before he reached them.
        {lineBreak()}
        5. He has been given the largest ummah out of all of the prophets. In a
        hadith in Sahih al-Bukhari (صحيح البخاري), he said Allah showed him the
        umam, and he saw an ummah as large as the eye could see, and he said,
        "Maybe this is my ummah," but he was told, "No, this is the ummah of
        Musa," and then he saw an ummah even larger that blocked his vision, and
        he was told, "This is your ummah."
        {lineBreak()}
        And in another hadith, he said to the sahaba, "Do you wish your ummah
        should be ⅓ of the people of Jannah (جنة - Paradise)?" They said,
        "Allahu'akbar (الله أكبر - Allah is the greatest)!" Then he was silent.
        Then he said, "Would you be happy if I told you if your ummah is ½ of
        the people of Jannah?" They said, "Allahu'akbar!" Then he was silent.
        Then he said, "By Allah, my hope is that my ummah shall be ⅔ of the
        people of Jannah!" (Subhan'Allah, modern estimates say there is close to
        2 billion Muslims on this earth today; so do the math — from the time of
        the Prophet PBUH until the Day of Judgement; and then compare this to
        the real followers of the prophets who came before, i.e. the Bani
        Israel, the actual followers of Isa AS; how few must they be?!)
        {lineBreak()}
        6. He has been given the most powerful miracle i.e. the miracle of the
        Quran. There is no miracle that compares to the Quran. One thing
        demonstrates this: look at any other miracle, e.g., the splitting of the
        Red Sea, when Isa resurrected the dead; we cannot see and experience
        those miracles ourselves except that we just believe they happened; but
        the Quran is a living miracle — it is a miracle that all of humanity has
        access to. It is a miracle that we can recite and the people can hear.
        {lineBreak()}
        7. The Night Journey of al-Isra' wal-Mi'raj (الإسراء والمعراج) — no
        other prophet has had the privilege of undertaking this journey. Even
        when Allah AWJ spoke with Musa, it was the Divine Speech given while
        Musa was on earth, on Tur Sina (طور سيناء - Mount Sinai). The Prophet
        PBUH was the only human to have been called up to the presence of Allah
        AWJ. He undertook a journey no other makhluq (مخلوق - creation), as far
        as we know, ever undertook: he went up to a maqam (مقام - place) that
        even Jibril (جبريل - Gabriel) said, "My permission stops here; you must
        go alone." "This is where it ends for me; I cannot go any further."
        {lineBreak()}
        8. He is the leader of all of humanity. He himself told us this in the
        hadith in Bukhari (بخاري) and Muslim (مسلم), "I am the sayyid (سيد - the
        one who is in charge of, i.e. the leader) of the children of Adam."
        Sayyid here means he is the master, the perfection of humanity. And he
        deserves to be the leader of humanity, and indeed he will be the leader
        of humanity on the DoJ.
        {lineBreak()}
        9. The Prophet PBUH will be the first to be resurrected on the DoJ
        [Sahih Bukhari] — the first grave to open up when the second trumpet is
        blown will be the Prophet's PBUH grave. This explains his name
        al-Hashir. And he will be the first to be clothed on the DoJ.
        {lineBreak()}
        10. He will be given the largest hawd (حوض) — a pool which is square in
        shape; it is so large that one side of it is as if it is from Makkah to
        Sana'a (صنعاء), which is half of the Arabian Peninsula.
        {lineBreak()}
        11. He will be given the Kawthar (الكوثر) — the main river of Jannah;
        and all rivers of Jannah split from it. It is as if the people of Jannah
        will drink water from the gift of the Prophet PBUH.
        {lineBreak()}
        12. He will be the first to cross over the Sirat (صراط - the Bridge) —
        the one to guide his ummah to Jannah; first to knock on the Doors of
        Jannah; he will be the first human to ever enter Jannah after Adam left
        it; and he will be the one in whose name the Gates of Jannah will be
        opened. The angel will ask, "Who is it?!" ... "To you I have been
        commanded to open." So it is his name that will be the permission to
        open up the Gates of Jannah. So the very first step to enter into Jannah
        is the right step of our Prophet Muhammad PBUH, followed by his ummah
        (even though we are the last ummah chronologically).
        {lineBreak()}
        13. Allah has blessed him with the highest level of Jannah: the pinnacle
        of al-Firdaws al-A'la (الفردوس الأعلى). It is an entire level. Some
        scholars have said Jannah is kind of like a pyramid, in that, the higher
        up you go, the fewer the people; there will come a point where the whole
        plain of Jannah is meant for only one person; it will be the pinnacle of
        al-Firdaws al-A'la; and it will be immediately underneath the Throne of
        Allah SWT; and it is called al-Fadila (الفضيلة). The Prophet PBUH said,
        "This Fadila is a level of Jannah that Allah has chosen for only one of
        His servants." And then he said modestly, "I hope that I am that person"
        (even though obviously there is no other human than him that is
        qualified for this position). And he PBUH wanted us to pray that Allah
        gives him the Wasila (الوسيلة) and Fadila (الفضيلة). And that's why
        every time the adhan (أذان) is called, we say:
        {lineBreak()}
        اللَّهُمَّ رَبَّ هَذِهِ الدَّعْوَةِ التَّامَّةِ، وَالصَّلاَةِ
        الْقَائِمَةِ، آتِ مُحَمَّدًا الْوَسِيلَةَ وَالْفَضِيلَةَ، وَابْعَثْهُ
        مَقَامًا مَحْمُودًا الَّذِي وَعَدْتَهُ "O Allah! Lord of this perfect
        call (perfect by not ascribing partners to You) and of the regular
        prayer which is going to be established, give Muhammad the Wasila and
        Fadila, and resurrect him on the Praiseworthy Station that You promised
        him (of)." [Sahih Bukhari]
        {lineBreak()}
        Even those who mocked him, ridiculed him, drew diagrams against him in
        this dunya, will be praising him on the DoJ. And in the akhira, he PBUH
        will be given al-Wasila and al-Fadila.
      </p>
    </div>
  );
}

export default Ep1;
