import "./Footer.css";

function Footer() {
  return (
    <div className="footerContainer">
      <div className="innerContainer">
        <p>Created by Hamza Majeed © 2021</p>
        <p>Transcribed by Br. Safwan Khan & Faizan</p>
      </div>
    </div>
  );
}

export default Footer;
