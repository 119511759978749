import { styles, lineBreak } from "./AllEpisodesSetup.js";

function Ep1() {
  return (
    <div style={styles.div}>
      <p>
        In our last halaqa (حلقة) we mentioned only around 15 khasa'is, but
        realize there are close to 50 special qualities that only the Prophet
        PBUH was given. Today insha'Allah we will begin by talking about his
        physical appearance PBUH.
        {lineBreak()}
      </p>
      <b style={styles.subtitle}>
        The Physical Appearance of Prophet Muhammad PBUH
      </b>
      {lineBreak()}
      <p>
        It is the sunnah of Allah (سنة الله - the custom of Allah) that He sends
        prophets with the most perfect characteristics, inner and outer, so that
        mankind has no reason to reject the messenger. As we know, Prophet Yusuf
        (يوسف - Joseph) was given half of all beauty. Some scholars say this
        means half of all beauty of mankind (this is the majority opinion); but
        some scholars say it means half of the beauty of Prophet Muhammad PBUH
        because —according to this opinion— the Prophet Muhammad PBUH is the
        most handsome and beautiful of the entire creation.
        {lineBreak()}
        We have many narrations that describe the physical features of our
        Prophet PBUH. It is reported in al-Tabarani's (الطبراني) al-Mu'jam
        al-Kabir (المعجم الكبير) and Bukhari's Kitab al-Manaqib (كتاب المناقب)
        that once a son of an old sahabiyat (صحابية - female Companion) asked
        his mother al-Rubayyi' bint Muawwidh (الربيع بنت معوذ) after the Prophet
        passed away, "What was the Prophet PBUH like?" She said, "O my dear son,
        if you were to have seen him, you would have thought that the sun had
        risen up." It is amazing that she described him as a sun, and another
        sahabi Ka'b ibn Malik (كعب بن مالك) described him as a moon. Ka'b said,
        "Whenever the Prophet PBUH was happy, his face would light up as if it
        was the full moon." It is beautiful that the sahaba are describing the
        Prophet PBUH with what they know to be the most beautiful.
        {lineBreak()}
        There is a tradition that almost makes you want to cry: The tradition of
        Amr ibn al-As (عمرو بن العاص). He used to be of the leaders of the
        Quraysh (قريش) who were against Islam, and he only had the opportunity
        to be a sahabi for a few years. Later on in his life he would say,
        "There was nothing that was sweeter or more beloved to me than to stare
        at the face of the Prophet PBUH." The beauty and nur (نور - light) of
        the Prophet PBUH was so great that he is saying there is nothing sweeter
        than to simply look at his face PBUH, "I could never get enough of
        looking at him. And yet were you to ask me how he looked, I couldn't
        describe him, because along with this desire to stare at him, there was
        also this awe I had that couldn't allow me to stare directly at him, and
        I had to look down." So there were two types of powers emanating from
        the Prophet PBUH: the first of them is attracting Amr's vision to him,
        and the second is making him look away. "Even though I loved to stare, I
        couldn't." This is coming from a nobleman of the Quraysh, and this is
        how humbled he was just by the appearance of the Prophet PBUH.
        {lineBreak()}
        Most of the descriptions of the Prophet PBUH come from the younger
        sahaba. Indeed they didn't have the same type of emotions as the older
        sahaba. Anas ibn Malik (أنس بن مالك) was introduced to the Prophet PBUH
        at 7 years old, and he would serve him all day. In the Shama'il (شمائل)
        of al-Tirmidhi (الترمذي), he said, "The Prophet PBUH was neither very
        tall such that he stood above the crowd, nor was he short such that he
        would be ignored. And he was neither extremely white nor was he ruddy
        brown." (Note: Generally speaking, when the Arabs use the word "white,"
        they mean a lightish brown; and what we call "white," they called it
        "yellow.") "The Prophet's PBUH hair was not in curls nor was it
        straight. And I never felt any velvet or silk softer than the hand of
        the Prophet PBUH, nor did I smell any musk or perfume more fragrant than
        the smell of the sweat of the Prophets PBUH." Indeed Ummi Salama (أم
        سلمة) used to collect his sweat in a jar to use it as perfume and
        medicine.
        {lineBreak()}
        Al-Bara' ibn Azib (البراء بن عازب) described the Prophet PBUH, "The
        Prophet PBUH was of medium stature and he had broad shoulders; and his
        hair was thick." (Note: The thickness of his hair PBUH has been narrated
        by at least five different sahaba. "His beard was very bushy," "His hair
        is very full," etc. And the Prophet PBUH would grow his hair until it
        went down to his earlobes.) "Once I saw him wearing a red hulla (حلة -
        cloak/jacket), and I never saw anything more beautiful than him on that
        night."
        {lineBreak()}
        The Prophet's PBUH cousin Ali ibn Abi Talib (علي بن أبي طالب) said, "The
        Prophet PBUH did not have a narrow face nor was it round, it was
        slightly ovalish. He had a whitish skin (lightish brown). His eyes were
        large with jet-black pupils, and his lashes were long. His joints were
        large as was his upper back (broad shoulders). And he did not have hair
        all over his body but he did have a fine line of hair extending from the
        chest to the navel. When he walked, he would walk briskly (fast) as if
        he is descending down a slope"—some scholars say it is as if Allah made
        the earth humbled to him. "When he turned, he would turn to face with
        his whole body. Between his two shoulders was the Seal of the
        Prophethood — and he was the seal of the prophets." (Note: The Prophet
        PBUH had a physical Seal on his body that Allah gave him. And this was
        to be a sign by which he would be recognized by the People of the Book
        [Jews and Christians] as a true prophet. It was a small growth of hair
        of a different color than the usual color of his body hair in between
        his shoulder blades [more on this later]. Most famously, Salman
        al-Farisi accepted Islam because of this Seal.) "Whoever unexpectedly
        saw him would stand in awe of him. And whoever accompanied him and got
        to know him would love him. And those who described him would always
        say, 'I have never seen anyone before him or after him who was like
        him.'"
        {lineBreak()}
        Jabir ibn Samura (جابر بن سمرة) was going home one night when there was
        a full moon. And he just happened to pass by the Prophet PBUH and he was
        wearing a red hulla. Jabir said, "I looked at the face of the Prophet
        PBUH and the full moon; and wallahi (والله - [I swear] by Allah), the
        Prophet PBUH was more beautiful in my eyes than the full moon."
        {lineBreak()}
        And many people accepted Islam just by seeing his face PBUH. The most
        famous is the chief rabbi of the Jews of Madinah (مدينة - Medina),
        Abdullah ibn Salam (عبد الله بن سلام). He heard this man coming claiming
        to be a prophet (the first day the Prophet PBUH came to Madinah), so he
        wants to go and see the message. This hadith is in Bukhari: Abdullah ibn
        Salam said, "As soon as I saw him, I knew this face cannot be the face
        of a liar." One conversation and he accepts Islam right then and there
        just by looking at his face PBUH.
        {lineBreak()}
      </p>

      <b style={styles.subtitle}>
        The Internal Qualities of Prophet Muhammad PBUH
      </b>
      {lineBreak()}
      <p>
        Along with the external beauty, of course our Prophet PBUH was blessed
        with internal beauty, humility, modesty, simple lifestyle, etc.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>1. Simple Lifestyle</b>
      {lineBreak()}

      <p>
        i) Sahih Muslim: Once Umar ibn al-Khattab (عمر بن الخطاب) entered upon
        the Prophet PBUH when he was lying in his small compartment in the
        masjid (مسجد - mosque), and there was nothing but a jug of water and a
        small bed made out of the branches of a date palm tree. When Umar comes
        in, he PBUH sits up to greet him. And because of the date palm branches,
        there was a red mark dented into the side of the Prophet PBUH. That is
        when Umar starts crying. (Note: This is 7th or 8th year of the Hijrah
        when the Prophet PBUH is the undisputed leader of a large chunk of
        Arabia.) Umar cried, "O Messenger of Allah! How can we allow you to live
        like this. Look at the kings of Rome and Persia how they live! Surely
        you deserve better!" The Prophet PBUH said, "O Umar, are you not happy
        that they have the dunya and we have the akhira?"
        {lineBreak()}
        ii) In Tirmidhi, Aisha bint Abi Bakr (عائشة بنت أبي بكر) describes, "The
        bed the Prophet PBUH used to sleep on was a leather skin that sometimes
        we would stuff with date palm leaves (to make it softer)." It is
        narrated that once one of the wives of the Prophet PBUH folded up her
        own half of the bed to give the Prophet PBUH extra (to make it more
        comfortable for him), and because of this, he slept longer than usual.
        When he woke up and realized what happened, he told her to change it
        back as he didn't want this comfort, as it prevented him from waking up
        for tahajjud (تهجد).
        {lineBreak()}
        iii) Aisha RA (radiyAllahu anha/رضي الله عنها - may Allah be pleased
        with her) says sometimes the Prophet PBUH would not taste meat for 6
        weeks. Her nephew Urwah ibn al-Zubayr (عروة بن الزبير), forty years down
        the line, asked her, "O my Mother (Mother of the Believers), how did you
        live?" She said, "We lived by eating the two dark things: dates and
        dirty water."
        {lineBreak()}
        iv) In a narration, it is said that when a good food was brought to Abd
        al-Rahman ibn Awf (عبد الرحمن بن عوف), he began to cry and said, "Till
        the day the Prophet PBUH died he never ate wheat bread to his fill! And
        not even his family!" And he added, "I worry that Allah SWT has allowed
        us to remain for such a long time that we are seeing all of these
        blessings come and the Prophet PBUH has gone on to something that is
        better." That is, he is worried that he is getting all of his ajr (أجر -
        reward) in this dunya when everyone else has gone on to enjoy their ajr
        in the Hereafter.
        {lineBreak()}
        v) In many narrations, it is said that Aisha RA also began to cry when
        good food was given to her; indeed she lived 40 years after the death of
        the Prophet PBUH when the money started flowing in, and sometimes people
        would gift her items and good food. And she would say the same thing as
        Abd al-Rahman ibn Awf, "The Prophet PBUH never ate [even] rustic or hard
        bread to his fill in his life."
        {lineBreak()}
        vi) Before the money came in and Islam came to power, the sahaba lived
        tough times in the early phase of Madinah. In Sahih Muslim, Abu Hurairah
        reported that one day or one night, the Prophet PBUH went out (of his
        house), and there he found Abu Bakr al-Siddiq (أبو بكر الصديق) RA
        (radiyAllahu anhu/رضي الله عنه - may Allah be pleased with him) and Umar
        RA. He said: "What has brought you out of your houses at this hour?"
        They said: "Ya Rasulullah, it is hunger." Thereupon he said: "By Him in
        Whose Hand is my life, what has brought you out has brought me out too;
        get up." They got up along with him, and (all of them) came to the house
        of an Ansari (Abu al-Haytham ibn al-Tayhan [أبو الهيثم بن التيهان]), but
        he was not at home. When his wife saw him she said: "Most welcome," and
        the Prophet PBUH said to her: "Where is so-and-so (Abu al-Haytham)?" She
        said: "He has gone to get some fresh water for us." When the Ansari came
        and he saw the Prophet PBUH and his two Companions, he said:
        "Alhamdulillah! No one has more honorable guests today than I (have)!"
        He then went out and brought them a bunch of ripe dates, dry dates, and
        fresh dates, and said: "Eat some of them." He then took hold of his long
        knife (for slaughtering a goat or a sheep). The Prophet PBUH said to
        him: "Beware of killing a milch animal." He slaughtered a sheep for them
        and after they had eaten of it and of the bunch and drank, and when they
        had taken their fill and had been fully satisfied with the drink, the
        Prophet PBUH said to Abu Bakr and Umar: "By Him in Whose Hand is my
        life, you will certainly be questioned about this bounty on the Day of
        Judgment [see Quran, 102:8]. Hunger brought you out of your house, then
        you did not return until this bounty came to you."
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>2. Humility</b>
      {lineBreak()}
      <p>
        i) The Prophet PBUH frequently rode donkeys even though he had a camel
        named al-Qaswa' (القصواء).
        {lineBreak()}
        ii) During the Battle of Badr (بدر), the Prophet PBUH distributed the
        camels amongst the people. There were ~313 people and ~75 camels, so
        roughly 3 or 4 people per camel (some people had horses, etc.); and the
        Prophet PBUH assigned Ali and Abu Lubaba (أبو لبابة) as his own
        companions. (And put yourself in their shoes: if you had to share a
        camel with the Prophet PBUH, of course you would say, "Take it, ya
        Rasulullah! It's yours!") And so they said to him PBUH, "We are young
        men in our 20s, we can walk. You take the camel, ya Rasulullah!" (Note:
        Indeed the leader and commander deserves extra respect and extra
        protection; the commander is someone who is not treated like the
        private, so the Prophet PBUH could have said, "Okay, fine." Or he PBUH
        even could have said, "No. Let us all share the camel.") [But] he PBUH
        didn't want to make them feel that he was doing them a favor by turning
        down their generous offer, so he PBUH smiled and said, "Neither are the
        two of you any stronger than me, and nor am I in any lesser need of the
        ajr (أجر - reward) than you two. So we are going to share [the camel]."
        Subhan'Allah.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>3. Manners</b>
      {lineBreak()}
      <p>
        i) Anas ibn Malik said, "I served the Prophet PBUH for 10 years, but not
        once did he rebuke me. Not once did the word uff (أف) comes from his
        mouth." (Note: Uff is the least word to express irritation; e.g. "tsk"
        is the equivalent of uff). "And not once did the Prophet PBUH say, 'Why
        did (or didn't) you do this (or that)?'" This is of the perfection of
        the manners of the Prophet PBUH. Subhan'Allah.
        {lineBreak()}
        (Tangent: Anas ibn Malik lived up to 110+ when the life expectancy was
        ~30 years, and of course this was of the effects of the du'a [دعاء -
        supplication] that the Prophet PBUH made for him. Anas's mother gave
        Anas to the Prophet in servitude all in hope that he PBUH would make
        du'a for her son. And he did, saying, "O Allah, give him barakah in his
        life, and in his wealth, and in his progeny." And Anas lived to see the
        results of this du'a: He was one of the last of the sahaba to die; and
        it is said he had so much wealth and whatever business he did was very
        fruitful; and he had grandchildren, great-grandchildren,
        great-great-grandchildren that were so much that he could not count them
        — all because of the du'a of the Prophet PBUH.)
        {lineBreak()}
        ii) There is another beautiful narration as well: Anas ibn Malik said,
        "Once the Prophet PBUH sent me on a chore. But on the way, I saw some
        kids playing, so I started playing with them and completely forgot about
        the chore"—Anas is a kid at the end of the day. Then the Prophet PBUH
        came out in search, and he finds him playing with the street children.
        Anas said, "Somebody held on to my ears and picked me up. I turned
        around and it was the Prophet PBUH smiling at me"—the Prophet PBUH was
        playing with Anas, not even getting irritated.
        {lineBreak()}
        iii) Even when people showed him the utmost contempt and disrespect, he
        PBUH maintained his dignity. In Sahih Muslim, Aisha RA reported that
        once a group of Jews came to the Prophet PBUH and sought his audience.
        When it was granted, they said to him PBUH, "Al-sam-u-alaykum (السام
        عليكم - death be upon you)"—in mockery of the greeting of the Muslims
        (al-salam-u-alaykum/السلام عليكم - peace be upon you). Aisha RA heard
        this, so she said in response, "Rather, may death be upon you, and also
        curse!" At this, the Prophet PBUH said to her, "O Aisha, verily Allah
        loves kindness in every matter [i.e. You don't have to stoop to their
        level]." When the Jews left, she said to the Prophet PBUH, "Didn't you
        hear what they said? [i.e. They said 'sam,' not 'salam'!]" The Prophet
        PBUH said, "Did you not hear that I said (to them): 'Wa alaykum (وعليكم
        - and upon you)'?"—he PBUH maintained his dignity and composure, and he
        didn't curse back at them.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>4. Bravery</b>
      {lineBreak()}

      <p>
        i) Ali ibn Abi Talib said, "When the fighting would become severe [in
        the Battle of Badr], we would seek refuge around the Prophet PBUH."
        {lineBreak()}
        ii) Anas ibn Malik said, "Once in Madinah in the middle of the night,
        people heard a loud commotion outside and they did not know what it was,
        so they timidly came outside wondering what the noise is. But they found
        the Prophet PBUH had already gone in the direction of the commotion
        alone, and he found the horse of Abu Talha (أبو طلحة) and simply rode
        the horse back without a saddle; and he had his sword around his neck;
        and he is galloping/coming back to the people of Madinah saying, 'You
        have nothing to fear, I have checked it out.'"
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>5. Generosity</b>
      {lineBreak()}
      <p>
        i) He PBUH would never refuse any request/asking of him. Ever. This is
        why a sahabiyat said, "It was possible for any little girl to go to the
        hand of the Prophet PBUH for help."
        {lineBreak()}
        ii) It is narrated that once the Prophet PBUH was wearing a garment that
        had holes in it, so one of the sahaba gifted him a very beautiful
        garment and he wore this garment. Then another sahabi asked him, "Ya
        Rasulullah, can you give me this garment as a gift?" Immediately the
        Prophet PBUH said yes. This is the very garment he just got as a gift!
        He went back home and came back wearing the tattered garment. All of the
        sahaba jumped on this sahabi, "How could have you asked the Prophet PBUH
        this! You knew he would never turn down a request!" He said, "Before you
        get angry at me... I am not doing this to wear! I want to use this as my
        kafan (كفن - shroud)! To show the generosity of the Prophet PBUH in
        front of Allah!"
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>6. Humor</b>
      {lineBreak()}
      <p>
        The Prophet PBUH was blessed with a great sense of humor. Having humor
        shows your humanity and down-to-earthness. There are so many instances
        of the jokes of the Prophet PBUH. All of his jokes are pure, clean, and
        truthful. Even his jokes are true. Even when he cause people to laugh,
        it is something that is true.
        {lineBreak()}
        i) Once an old lady came to the Prophet PBUH and said, "O Messenger of
        Allah! Make du'a that Allah causes this old lady (herself) to enter
        Jannah." The Prophet PBUH looked at her and said, "O my aunt, have you
        not been informed that old ladies do not enter Jannah?" At this she
        began to wail and cry. Then he told her, "Do not cry, for wallahi, old
        ladies do not enter Jannah, but Allah SWT will first make her into a
        young, fair maiden, and then she will enter Jannah." That is, you are
        going to be young and beautiful when you enter Jannah. And then he PBUH
        recited the verse:
        {lineBreak()}
        إِنَّا أَنشَأْنَاهُنَّ إِنشَاءً "We bring them forth with a new
        beginning." [see Quran, 56:35]
        {lineBreak()}
        ii) And the story of Aisha RA when the Prophet PBUH was on his deathbed
        (note: he literally had 4-5 days left to live; and of course nobody knew
        this at the time except him): It so happened that Aisha herself felt a
        little sick on one of the days and she had a severe headache, so she was
        crying, "O my head! O my head!" The Prophet PBUH said, "Rather, O *my*
        head! (I have a worse headache than you)," and to calm her down he
        cracked some jokes, "O Aisha, what would you lose if you die right now?
        The one to do ghusl (غسل - washing) for you, and to pray your janazah
        (جنازة - funeral prayer), and to bury you, would be me." Aisha RA says,
        "I am sure you would like that to happen because then you would be free
        to go to your other wives." Subhan'Allah, he is joking on his deathbed.
        And from this joke, scholars have derived fiqh (فقه - Islamic
        jurisprudence): the permissibility of a spouse doing ghusl for [the
        janazah of] his/her spouse — because the Prophet PBUH never spoke except
        the truth. Al-Shawkani (الشوكاني) has a chapter in his book concerning
        this subject, and he mentions this hadith.
        {lineBreak()}
        iii) It is reported in Tirmidhi that there was a young man by the name
        of Zahir ibn Haram (زاهر بن حرام) whom the Prophet PBUH used to love a
        lot. We do not know much about him but there was something about him
        that the Prophet PBUH loved. He was a simple sincere believer. Once the
        Prophet PBUH saw him in the souq (سوق - marketplace) selling some
        things, "Who is going to buy this from me? Who is going to buy this from
        me?" etc. The Prophet came from behind quietly, grabbed him, and gave
        him a body-lock/bear-hug. Zahir asked, "Who is this?!" When he saw it
        was the Prophet PBUH, he leaned back to the chest of the Prophet PBUH
        trying to get as much barakah. Then as a joke, the Prophet PBUH shouted
        out, "Who is going to buy this abd (عبد - slave) from me?" (Note: In
        those days if you say who is going to buy an "abd" in a marketplace, of
        course it meant a real slave. But here the Prophet PBUH meant abd =
        slave of Allah. Again, even in his joke he PBUH is not lying.) Zahir
        said, "O Messenger of Allah, in that case you are going to get a very
        bad bargain. I am not going to sell very expensive"—Zahir had a very low
        self-esteem. The Prophet PBUH said, "You are very expensive in the Eyes
        of Allah SWT." This is a beautiful story that shows the humanity of the
        Prophet PBUH.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>Prophet Muhammad's PBUH Love For His Ummah</b>
      {lineBreak()}
      <p>
        i) The Prophet PBUH had an immense amount of love for his ummah. Allah
        AWJ says:
        {lineBreak()}
        لَقَدْ جَاءَكُمْ رَسُولٌ مِّنْ أَنفُسِكُمْ عَزِيزٌ عَلَيْهِ مَا
        عَنِتُّمْ حَرِيصٌ عَلَيْكُم بِالْمُؤْمِنِينَ رَءُوفٌ رَّحِيمٌ "There has
        come to you a messenger from amongst you, he finds it difficult to bear
        your hardships, he is ever eager for you, to the believers he is full of
        compassion and mercy." [Quran, 9:128]
        {lineBreak()}
        ii) It is narrated in Sahih Muslim that: Once the Prophet PBUH was
        reciting the Quran and he came across a number of verses. Of them is the
        famous verse when Ibrahim AS says, "My Lord [...] whoever follows me is
        with me, and whoever disobeys me—then surely You are [still]
        All-Forgiving, Most Merciful" [see Quran, 14:36]. And then he PBUH came
        across the verse of Isa AS in Surah al-Ma'idah (سورة المائدة) saying,
        "[O Allah,] If You punish them, they belong to You after all. But if You
        forgive them, You are surely the Almighty, All-Wise" [see Quran, 5:118].
        (In other words, Isa AS wanted Allah to forgive his ummah.) So the
        Prophet PBUH recites these two verses both of them regarding prophets
        making du'a for their ummah, and then he starts thinking of his own
        ummah, so he raises his hands to Allah and says, "O Allah, my ummah! O
        Allah, my ummah!" and he began to cry. Allah told Jibril, "Go to
        Muhammad and ask him why is he crying"—and of course Allah knew why he
        was crying. Jibril came to Prophet Muhammad PBUH and asked him, "Why are
        you crying?" The Prophet PBUH said, "I am crying thinking of my ummah
        and thinking of what will be their fate." Then Jibril went back to
        Allah. Allah said, "O Jibril! Go back to Muhammad and tell him We shall
        please you, and not displease you regarding your ummah." In other words,
        Allah is going to bless the ummah of the Prophet PBUH because He SWT
        wants to please the Prophet PBUH!
        {lineBreak()}
        iii) The greatest indication of the love that the Prophet PBUH had for
        his ummah: Allah SWT has given every single prophet one wish that He
        will never reject. Some of the prophets used it against their people who
        disobeyed. E.g. Nuh AS after making dawah for 950 years, he said, "My
        Lord! Do not leave a single disbeliever on earth" [Quran, 71:26]. So
        Allah destroyed all humanity except the Mu'minun (مؤمنون - Believers):
        Nuh AS, some of his children, and their spouse. And Ibrahim AS made that
        famous du'a for the Prophet PBUH, "Our Lord, send from amongst them a
        messenger." [see Quran, 2:129] (Note: This verse is why the Prophet PBUH
        said, "I am the du'a of my father Ibrahim.") And Musa AS made du'a
        against Fir'awn (فرعون - Pharaoh) [see Quran, 10:88], and it was
        answered. Sulayman (سليمان - Solomon) AS made the du'a, "My Lord!
        Forgive me, and grant me an authority that will never be matched by
        anyone after me. You are indeed the Giver [of all bounties]" [Quran,
        38:35] So Allah gave him powers no one had, e.g. given the control over
        wind & jinn [see Quran, 38:36-38] — jinns were building and diving and
        bringing treasures etc. for Sulayman. And he could speak to animals [see
        Quran, 27:16] and even to ants! All the animals became his servants [see
        Quran, 27:17]. This is the du'a of Sulayman.
        {lineBreak()}
        You see now what this du'a can give the prophets.
        {lineBreak()}
        Our Prophet PBUH had the biggest and greatest du'a, and he used it for
        us. He said, "Every single rasul (رسول - messenger) and nabi (نبي -
        prophet), Allah has given him one du'a that He never rejects. And every
        single nabi has used it up in this world, except for me. I have saved it
        for my ummah and I will use it for them on the Day of Judgement." And
        the du'a will be, "O Allah, forgive my entire ummah!" And Allah will
        accept this du'a, in that, every single Muslim who believes and acts
        upon even a little bit of his teachings will eventually be forgiven and
        enter Jannah. All because the Prophet PBUH saved that one du'a for us.
        There can be no greater sacrifice than this. This shows us the love that
        he PBUH had for us.
      </p>
      {lineBreak()}
      <b style={styles.subtitle}>Conclusion</b>
      {lineBreak()}
      <p>
        We conclude by mentioning a beautiful hadith reported in Bukhari: Anas
        ibn Malik narrates: A man came to the Prophet PBUH and said, "O
        Messenger of Allah, when is the Day of Judgement?" The Prophet PBUH
        asked him back, "What have you prepared for it when it comes?" The man
        was silent for a while, and then he said, "I really do not have that
        much salah (صلاة - prayer), siyam (صيام - fasting), or sadaqa (صدقة -
        charity), but I have a genuine love for Allah and His Messenger." The
        Prophet PBUH said, "A man shall be with he whom he loves [in Jannah]."
        Anas commented, "Wallahi, we were never as happy and overjoyed as we
        were on that day when we heard that a man shall be with he whom he
        loves, because we love the Prophet PBUH and we want to be with him [in
        Jannah], but we thought our levels would be so different we would never
        get to see him."
        {lineBreak()}
        So let us have a genuine love for the Prophet PBUH, let us understand
        the sacrifices he made for us, let us understand what a great
        personality he was, and most importantly let us study his life and times
        so that we can implement and follow his teachings and come closer to
        Allah SWT. Allah says in the Quran:
        {lineBreak()}
        قُلْ إِن كُنتُمْ تُحِبُّونَ اللَّهَ فَاتَّبِعُونِي يُحْبِبْكُمُ اللَّهُ
        وَيَغْفِرْ لَكُمْ ذُنُوبَكُمْ "Say, 'If you truly love Allah, then
        follow me. Allah will love you and forgive your sins.'" [see Quran,
        3:31]
        {lineBreak()}
        May Allah SWT make us amongst those who truly follow the Prophet PBUH,
        and may He forgive our sins.
      </p>
    </div>
  );
}

export default Ep1;
