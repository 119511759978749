function DefaultEp() {
  return (
    <div>
      <h1 style={{textAlign: "center"}}>
        Please select an episode in{" "}
        <a
          style={{ textDecoration: "none", color: "rgb(255, 145, 0)" }}
          href="/"
        >
          Home
        </a>
      </h1>
    </div>
  );
}

export default DefaultEp;
