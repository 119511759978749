import Ep0 from "./Ep0";
import Ep1 from "./Ep1";
import Ep2 from "./Ep2";
import Ep3 from "./Ep3";

const EpisodeComponents = [Ep0, Ep1, Ep2, Ep3];

const TOTAL_EPISODES = EpisodeComponents.length;

const styles = {
  div: {
    color: "whitesmoke",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "75%",
    marginTop: "30px",
  },

  subtitle: {
    fontSize: "25px",
    alignSelf: "center",
  },

  bold: {
    textTransform: "bold",
  },
};

const lineBreak = () => {
  return (
    <div>
      <br></br>
    </div>
  );
};

export { EpisodeComponents, TOTAL_EPISODES, styles, lineBreak };
