import "./App.css";
import Home from "./Home.js";
import Content from "./Content.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState } from "react";
import Error from "./Error";

function App() {
  const [episodeTitle, setEpisodeTitle] = useState("");
  const [episodeNum, setEpisodeNum] = useState("0");

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              setEpisodeTitle={setEpisodeTitle}
              setEpisodeNum={setEpisodeNum}
            />
          }
        />
        <Route
          path="/content/:episode"
          element={
            <Content EpisodeTitle={episodeTitle} EpisodeNum={episodeNum} />
          }
        />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  );
}

export default App;
