import "./Back.css";
import back from "./images/back.png";

function Back() {
  return (
    <div className="backContainer">
      <a href="/">
        <img src={back} alt="back"></img>
      </a>
    </div>
  );
}

export default Back;
