import "./Nav.css";
import titleImage from "./images/title-image.jpg";

function Title() {
  return (
    <div className="main">
      <div className="navContainer">
        <div className="home">
          <a className="homeLink" href="/">
            <h1>Home</h1>
          </a>
        </div>

        <div className="titleContainer">
          <h1 className="title">Seerah</h1>
          <img src={titleImage} alt="Prophet PBUH" className="titleImage"></img>
        </div>

        <div className="login">
          <div className="greetUser">
            <h4>
              Hello, <span>Sign In</span>
            </h4>
          </div>
          <a href="/login">
            <button className="loginButton" type="button">
              Login
            </button>
          </a>
        </div>
      </div>
      <div className="transcript">
        <h2>
          TRANSCRIPT OF SH. DR. YASIR QADHI'S SEERAH OF PROPHET MUHAMMAD PBUH
          LECTURES
        </h2>
      </div>
    </div>
  );
}

export default Title;
