import "./Content.css";
import Nav from "./Nav.js";
import Footer from "./Footer.js";
import Back from "./Back.js";

import { EpisodeComponents } from "./episodes/AllEpisodesSetup.js";

function Content({ EpisodeTitle, EpisodeNum }) {
  let EpisodeComponent = EpisodeComponents[EpisodeNum];
  return (
    <div>
      <Nav />
      <div className="contentContainer">
        <h1>{EpisodeTitle}</h1>
        <EpisodeComponent />
      </div>
      <Back />
      <Footer />
    </div>
  );
}

export default Content;
