import "./Home.css";
import Nav from "./Nav.js";
import Episode from "./Episode.js";
import Footer from "./Footer.js";

import { TOTAL_EPISODES } from "./episodes/AllEpisodesSetup.js";

function Home({ setEpisodeTitle, setEpisodeNum }) {
  const generateEpisodes = () => {
    let episodes = [];
    for (let i = 1; i < TOTAL_EPISODES; i++) {
      const episodeFile = require(`./episodes/episode${i}.js`);
      const episode = episodeFile.data.episode;
      const title = episodeFile.data.title;
      const video = episodeFile.data.video;
      episodes.push(
        <Episode
          key={episode}
          Episode={episode}
          Title={title}
          Video={video}
          setEpisodeTitleNested={setEpisodeTitle}
          setEpisodeNumNested={setEpisodeNum}
        />
      );
    }
    return episodes;
  };

  return (
    <div>
      <Nav />
      {generateEpisodes()}
      <Footer />
    </div>
  );
}

export default Home;
