import "./Error.css";
import Nav from "./Nav";

function Error() {
  return (
    <div>
      <Nav />
      <div className="notFound">
        <h1>
          Page not found, return to <a href="/">Home</a>
        </h1>
      </div>
    </div>
  );
}

export default Error;
